var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "dashboardModule",
    staticClass: "dashboard-module"
  }, [_c('div', {
    class: ['dashboard-module__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _c('insight-filters', {
    on: {
      "filtersChange": _vm.onFiltersChange
    }
  }), _c('section', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.insights.length,
      expression: "insights.length"
    }],
    staticClass: "m-post-list"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "m-post-list__container"
  }, [_vm._l(_vm.insights, function (insight, index) {
    return _c('post-box', {
      key: insight.id,
      attrs: {
        "details": insight,
        "featured": index == 0
      }
    });
  }), _c('b-pagination', {
    attrs: {
      "align": "center",
      "total-rows": _vm.totalCount,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "limit": _vm.perPage,
      "prev-text": "Previous",
      "next-text": "Next",
      "first-number": true,
      "last-number": true,
      "hide-goto-end-buttons": true
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 2)])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }